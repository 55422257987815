export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')];

export const server_loads = [0,4];

export const dictionary = {
	"/": [~5],
	"/app/calendar": [7,[2]],
	"/app/create-password": [~8,[3]],
	"/app/dashboard": [9,[2]],
	"/app/forums": [10,[2]],
	"/app/garden/[gardenId]/harvests": [~11,[2]],
	"/app/garden/[gardenId]/harvests/[harvestId]": [~12,[2]],
	"/app/garden/[gardenId]/lesson/[lessonId]": [13,[2]],
	"/app/garden/[gardenId]/lesson/[lessonId]/share": [14,[2]],
	"/app/guidebook": [15,[2]],
	"/app/learn/[courseSlug]/lesson/[lessonId]": [~16,[4]],
	"/app/resources": [~17,[2]],
	"/app/settings": [18,[2]],
	"/app/shop": [~19,[2]],
	"/app/support": [20,[2]],
	"/app/team": [21,[2]],
	"/login": [22],
	"/logout": [23],
	"/[locationSlug]": [~6]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};